import * as React from "react";
import { useEffect } from "react";
import { OnboardingLanding } from "../../components/PageSegments/onboarding/OnboardingLanding";
import { useOnboardingContext } from "../../components/PageSegments/onboarding/OnboardingContext";
import { landed_from_promo } from "../../services/mixpanel/mixpanel";
import { BasicLayout } from "../../layouts/BasicLayout";

// DEPRECATED
export default data => {
  const { search } = data.location;
  const { setPromoName, setCouponId } = useOnboardingContext();

  useEffect(() => {
    if (search) {
      const urlParams = new URLSearchParams(search);

      const promo = urlParams.get("promo");
      const cid = urlParams.get("cid");
      if (promo) {
        setPromoName(promo);
        landed_from_promo(promo);
      }

      if (cid) {
        setCouponId(cid);
      }
    }
  });

  return (
    <BasicLayout>
      <OnboardingLanding/>
    </BasicLayout>
  );
};
