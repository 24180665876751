import React from "react";
import styled from "styled-components";
import { navigate } from "gatsby-link";
import { AppleStoreIcon, Stars } from "../../../content/assets";
import { mobileSize } from "../../../util/variables";
import { fbq } from "../../../services/pixel";
import theme from "../../../util/theme";
import { AppButton, AppLogo } from "../../ui";

export const OnboardingLanding = () => {
  const handleSubmit = () => {
    fbq("trackCustom", "start_questions");
    navigate("/onboarding/questions");
  };

  return (
    <Container>
      <ContentContainer>
        <FlexCol>
          <AppLogo style={{ marginBottom: 40 }}/>
          <Title>The world&apos;s most important knowledge, <Blue>visualized</Blue></Title>
        </FlexCol>
        <FlexCol>
          <Ratings>
            <FlexRow>
              <img src={AppleStoreIcon}/>
              <span>7.4K Ratings</span>
            </FlexRow>
            <img src={Stars}/>
          </Ratings>
          <AppButton onClick={handleSubmit}>Get Started</AppButton>
        </FlexCol>
      </ContentContainer>
    </Container>
  );
};

const Container = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: #eef2f6;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 85%;
  height: 75%;
  background: #FEFEFE;
  padding: 5rem;
  border-radius: 1rem;
  -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  -moz-box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;

  @media ${mobileSize} {
    justify-content: space-between;
    padding: 3rem 1rem 1rem 1rem;
    height: 95%;
    width: 90%;
  }
`;

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;

  @media ${mobileSize} {
    margin-bottom: 1rem;
  }
`;

const Logo = styled.img`
  margin-bottom: 5rem;
  height: 2.5rem;

  @media ${mobileSize} {
    margin-bottom: 9rem;
  }
`;

const Title = styled.h1`
  text-align: center;
  max-width: 75%;
  letter-spacing: 1px;
  line-height: 6.8rem;

  @media ${mobileSize} {
    font-size: 3.6rem;
    max-width: 90%;
    line-height: 51px;
  }
`;

const Blue = styled.span`
  color: ${theme.PRIMARY_COLOR};
`;

const Ratings = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
  font-size: 1.8rem;
  * {
    margin-right: 1rem;
  }

  @media ${mobileSize} {
    flex-direction: column;
    * :last-child {
      margin-right: 0;
    }
    
  }
`;

const Button = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background: linear-gradient(
      90deg,
      rgba(115, 123, 253, 0.75) 1.68%,
      rgba(50, 123, 210, 0.75) 116.81%
    ),
    #1e85e2;
  border-radius: 32px;
  cursor: pointer;
  color: #fefefe;
  width: 343px;
  @media ${mobileSize} {
    width: 100%;
  }
`;
